@import "../../assets/styles/utils";
footer{
  @include res(padding-bottom,0,(xs:80px));
  .mob-contact{
    width: 100%;
    height: auto;
    @include res(display,none,(xs:block));
    .map{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 100%;
        height: 100%;
        display: block;
        margin: 0 auto;
        object-fit: cover;
      }
      .mask{
        width: 70%;
        height: 80%;
        background: #fff;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .footerimg{
    width: 100%;
    height: 378px;
    @include res(height,378px);
    position: relative;
    background-image: url('./images/footer.jpg');
    @include res(background-position,50%);
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 767px){
      height: 530px;
      background-image:none;
      background: #333;
    }
    .footer-mask{
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      display: flex;
      align-items: center;
      @media (max-width: 767px){
        background: none;
        justify-content: center;
        flex-flow: column;
      }
      .container{
        @include res(width,100%,(xs:90%));
        @include res(display,flex,(xs:block));
        align-items: flex-start;
        justify-content: space-between;
        .left{
          @include res(width,30%,(xs:100%));
          .logo{
            @include res(width,68%,(xs:80%));
            height: auto;
            @include res(display,block,(xs:none));
            img{
              width: 100%;
              height: 100%;
              display: block;
              margin: 0 auto;
            }
          }
          .mob{
            @include res(display,block);
            align-items: flex-start;
            justify-content: space-between;
            @include res(margin-top,0,(xs:20px));
            .address{
              @include res(margin-top,20px,10 / 20);
              @include res(margin-bottom,20px,10 / 20);
              li{
                @include res(font-size,16px,14 / 16);
                @include res(margin-bottom,10px,6 / 10);
                color: #fff;
                @media (max-width: 767px){
                  border-bottom:1px solid #bbb;
                  height: 50px;
                  line-height: 50px;
                  margin-bottom: 0;
                  background: url('./images/footer-arrow.png') no-repeat left;
                  background-size: 1.5%;
                  padding-left: 20px;
                }
                @media (max-width: 415px){
                  background-size: 3%;
                  padding-left: 20px;
                }
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
            .ewm{
              @include res(display,flex,(xs:none));
              align-items: center;
              justify-content: flex-start;
              img{
                @include res(width,100px,80 / 100);
                @include res(height,100px,80 / 100);
                @include res(margin-right,30px,15 / 30);
                display: block;
                &:last-child{
                  margin-right: 0;
                }
              }
            }
          }
        }
        .center{
          @include res(width,30%,(xs:100%));
          @include res(margin-top,0,(xs:20px));
          @include res(display,flex,(xs:none));
          ul{
            @include res(display,block,(xs:flex));
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
              @include res(margin-bottom,10px,6 / 10);
              @include res(width,100%,(xs:48%));
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              a{
                @include res(font-size,16px,14 / 16);
                color: #fff;
                transition: .3s;
                &:hover{
                  color: $color-title;
                }
              }
            }
          }
        }
        .right{
          @include res(width,26%,(xs:100%));
          @include res(margin-top,0,(xs:40px));
          .title{
            @include res(font-size,20px);
            color: #fff;
            @include res(text-align,left,(xs:center));
            @media (max-width: 415px){
              font-weight: bolder;
            }
          }
          p{
            @include res(font-size,14px,12 / 14);
            color: #fff;
            @include res(text-align,left,(xs:center));
            @include res(display,block,(xs:none));
          }
          .form{
            @include res(margin-top,20px);
            ul{
              li{
                @include res(width,100%);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                @include res(font-size,16px,14 / 16);
                @include res(margin-bottom,15px,10 / 15);
                color: #fff;
                span{
                  flex-shrink: 0;
                }
                input{
                  @include res(height,30px);
                  flex: 1;
                  @include res(margin-left,20px,10 / 20);
                  border: 1px solid #fff;
                  outline: none;
                  background: none;
                  color: #333;
                  padding-left: 6px;
                  @include res(font-size,16px,14 / 16);
                  background: #fff;
                }
              }
            }
            .submit{
              @include res(font-size,16px,14 / 16);
              color: #fff;
              background: $color-main;
              text-align: center;
              letter-spacing: 10px;
              @include res(margin-top,10px);
              @include res(padding-top,8px,6 / 8);
              @include res(padding-bottom,8px,6 / 8);
              cursor: pointer;
              transition: .3s;
              @media (max-width: 415px){
                letter-spacing: 0;
                width: 36%;
                margin: 20px auto 0;
                border-radius: 4px;
              }
              &:hover{
                background: #fff;
                color: $color-main;
              }
            }
          }
        }
      }
      // 手机端版权
      .copyright-mob{
        width: 100%;
        @include res(display,none,(xs:block));
        padding: 0 15px;
        margin-top: 30px;
        ul{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          li{
            width: auto;
            margin-bottom: 10px;
            &:last-child{
              margin-bottom: 0;
            }
            a{
              font-size: 14px;
              color: #fff;
            }
          }
        }
      }
    }
  }
  // 电脑端版权
  .copyright-pc{
    width: 100%;
    @include res(height,70px,(xs:100px));
    background: $color-main;
    @include res(padding-top,0,(xs:15px));
    @include res(padding-bottom,0,(xs:15px));
    @include res(display,block,(xs:none));
    ul{
      width: 100%;
      height: 100%;
      @include res(display,flex);
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      li{
        @include res(width,auto,(xs:100%));
        @include res(margin-right,80px,0 / 80);
        @include res(text-align,left,(xs:center));
        a{
          @include res(font-size,16px,14 / 16);
          color: #fff;
        }
      }
    }
  }
  // 手机端底部导航
  .footer-nav-mob{
    width: 100%;
    height: auto;
    background: $color-main;
    padding: 10px 30px;
    @include res(display,none,(xs:block));
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    ul{
      display: flex;
      align-items: center;
      justify-content: space-between;
      li{
        a{
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          .icon{
            width: 36px;
            height: auto;
            img{
              width: 100%;
              height: 100%;
              display: block;
              margin: 0 auto;
              object-fit: cover;
            }
          }
          .nav-text{
            font-size: 14px;
            color: #fff;
            margin-top: 5px;
          }
        }
      }
    }
  }
}