@import "../../assets/styles/utils";
.indexleftnav{
  @include res(width,120px,80 / 120);
  @include res(display,block,(sm:none !important));
  background: #fff;
  position: fixed;
  @include res(top,38%,0 / 38);
  left: 3%;
  z-index: 9;
  box-shadow:0 0 6px #ccc;
  .block{
    width:100%;
    height: 40px;
    background: $color-main;
  }
  ul{
    display: flex;
    flex-flow: column;
    li{
      width: 100%;
      @include res(height,120px,80 / 120);
      text-align: center;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      &:hover{
        .iconfont,p{
          color: $color-main;
        }
      }
      .iconfont{
        @include res(font-size,36px,14 / 36);
        color: #000;
        transition: .3s;
      }
      p{
        @include res(font-size,16px,14 / 16);
        color: #333;
        @include res(margin-top,4px);
        transition: .3s;
      }
      .subcom{
        @include res(width,120px,80 / 120);
        @include res(height,120px,80 / 120);
        @include res(line-height,120px,80 / 120);
        text-align: center;
        background: #fff;
        position: absolute;
        left: 100%;
        top: 0;
        display: none;
        span{
          @include res(font-size,16px,14 / 16);
        }
        img{
          width: 100%;
          height: 100%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}