@font-face {
  font-family: "iconfont"; /* Project id 3419616 */
  src: url('iconfont.woff2?t=1653311410294') format('woff2'),
       url('iconfont.woff?t=1653311410294') format('woff'),
       url('iconfont.ttf?t=1653311410294') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-guanbi:before {
  content: "\eca0";
}

.icon-iconshang:before {
  content: "\e62b";
}

.icon-iconguanbi:before {
  content: "\e889";
}

.icon-dianhua:before {
  content: "\eb23";
}

.icon-weixin:before {
  content: "\e733";
}

.icon-sao:before {
  content: "\e7c1";
}

.icon-tel:before {
  content: "\e96a";
}

