@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  // font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: 'roboto', '微软雅黑';
  overflow-x: hidden;
  line-height: 1.5;
  position: relative;
  min-height: 100vh;
  color: $color-text;
  @include res(font-size, $text-size-base, $text-size-ratio);
  @include res(background-color, $color-white, (sm:$color-light-blue));
}

//主体内容部分宽度
.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);
  &.w1200{
    max-width: 1200px;
  }
  &.w1320{
    max-width: 1320px;
  }
  &.w1600{
    max-width: 1600px;
  }
}
.common-title{
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-weight: bold;
  @include res(color,#000);
  @include res(font-size,20px,16 / 20);
  span{
    font-weight: normal;
    margin-left: 10px;
    @include res(font-size,16px,14 / 16);
    @include res(color,#919191);
  }
}

.mob-common-title{
  font-size: 20px;
  font-weight: bolder;
  color: #fff;
  background: $color-main;
  width: 160px;
  height: 46px;
  margin: 30px auto;
  @include res(display, none, (xs:flex));
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.ny-banner {
  width: 100%;
  @include res(height,600px, 158 / 600);
  @include res(margin-top,$header-height-base, $header-height-ratio);
  position: relative;
  img {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    object-fit: cover;
  }
  .ny-banner-text{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    @include res(font-size,40px,20 / 40);
    font-weight: bold;
    color: #fff;
  }
}

.ny-nav {
  @include res(padding-bottom, 100px, 30 / 100);
  .ny-nav-mob{
    @include res(display, none, (sm:flex));
    flex-flow: column;
    align-items: center;
    position: relative;
    .title{
      background: #fff;
      color: $color-main;
      font-size: 18px;
      font-weight: bold;
      padding: 8px 40px 2px;
      border-radius: 0 0 20px 20px;
      position: relative;
      z-index: 9;
      text-align: center;
    }
    ul{
      width: 100%;
      background: $color-main;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 36px 3% 10px;
      margin-top: -20px;
      overflow-x: auto;
      li{
        margin-right: 13px;
        flex-shrink: 0;
        &:last-child{
          margin-right: 0;
        }
        a{
          font-size: 15px;
          color: #000;
        }
      }
    }
    .select{
      background: $color-main;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      padding: 4px 40px 4px;
      border-radius: 0 0 20px 20px;
      text-align: center;
    }
  }

  .navCon {
    @include res(display, flex, (sm:block));
    @include res(margin-top, 100px, 30 / 100);
    .leftnav {
      @include res(width, 280px, 100 / 280);
      height: auto;
      max-height: 500px;
      background: #c2c2c2;
      flex-shrink: 0;
      @include res(display, block, (sm:none));
      @include res(padding, 40px, 20 / 40);
      @media (max-width: 991px) {
        width: 100%;
      }
      .title{
        @include res(font-size, 24px, 18 / 24);
        text-align: left;
        color: #333;
      }
      ul {
        @media (max-width: 991px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        li {
          width: 100%;
          text-align: left;
          @include res(margin-top, 20px, 10 / 20);
          a {
            width: 100%;
            height: 100%;
            display: block;
            color: #333;
            @include res(font-size, 16px, 14 / 16);
            transition: .3s;
          }
          &.active {
            a {
              color: #fff;
            }
          }
          &:hover {
            a {
              color: #fff;
            }
          }
        }
      }
    }

    .rightcon {
      @include res(margin-left, 130px, 25 / 130);
      position: relative;
      @media (max-width: 991px) {
        margin-left: 0;
      }
      .selectNav{
        @include res(display, block);
        h1{
          color: $color-main;
          font-weight: bold;
          @include res(font-size, 36px, 24 / 36);
          text-align: center;
        }
        .tit{
          @include res(font-size, 20px, 16 / 20);
          text-align: center;
          @include res(margin-top,10px,6 / 10);
        }
      }
    }
    .back {
      width: 60%;
      position: absolute;
      left: -25%;
      top: 63%;
      z-index: -99;
      @include res(display, block, (sm:none));

      img {
        width: 100%;
      }
    }

    p {
      @include res(margin-bottom, 30px, 10 / 30);
      @include res(font-size, 16px, 14 / 16);
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      @include res(margin-bottom, 30px, 10 / 30);
    }
  }
}