@import "../../assets/styles/utils";
header{
  overflow: hidden;
  .header{
    width: 100%;
    @include res(height, $header-height-base, $header-height-ratio);
    @include res(display,block, (sm:flex));
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition: 0.3s;
		@media (max-width: 991px){
      background: #265d4d;
      padding: 0 20px;
		}
		@media (max-width: 767px){
			height: 60px !important;
      padding: 0 20px;
		}
    &.scroolActive{
      .nav{
        height: 60px;
        .topNav{
          line-height:60px;
          .item{
            a{
              font-size: 17px;
            }
          }
        }
        .tel-lang{
          .tel{
            .iconfont{
              font-size: 16px;
            }
            .num{
              font-size: 14px;
            }
          }
          .lang{
            ul{
              li{
                a{
                  font-size: 14px;
                }
              }
            }
          }
        }
        .logo{
          width: 15%;
        }
      }
    }
    .nav{
      width: 100%;
      height: 100%;
      @include res(padding-left,60px, 10 / 60);
      @include res(padding-right,60px, 10 / 60);
      @include res(display,flex,(sm:none));
      align-items: center;
      justify-content: space-between;
      background: #265d4d;
      transition: 0.3s;
      .logo{
        @include res(display,block,(sm:none));
        @include res(width,20%,15 / 20);
        height: auto;
        transition: 0.3s;
        img{
          width: 100%;
          height: 100%;
          display: block;
          margin: 0 auto;
          transition: 0.3s;
        }
      }
      .topNav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        @include res(margin-left,0, (mmd:20px));
        @include res(margin-right,0, (mmd:20px));
        .item{
          @include res(margin-right,80px, 20 / 80);
          position: relative;
          height: 100%;
          display: flex;
          white-space:nowrap;
          @media (max-width: 1441px){
            margin-right: 60px;
          }
          &:last-child{
            margin-right: 0;
          }
          &:hover{
            >a{
              &::after{
                width: 100%;
              }
            }
          }
          &.active{
            >a{
              &::after{
                width: 100%;
              }
            }
          }
          a{
            @include res(font-size,16px, 14 / 16);
            color: #fff;
            transition: .3s;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            position: relative;
            &::after{
              content: '';
              position: absolute;
              width:0;
              bottom: 0;
              display: block;
              transition: .3s ease-in-out;
              border-bottom: 2px solid #fff;
            }
          }
          .subnav{
            width: 120px;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -60px;
            background: #265d4d;
            display: none;
            ul{
              li{
                transition: .3s;
                border-bottom: 1px solid #efefef;
                text-align: center;
                @include res(height,50px,30 / 50);
                @include res(line-height,40px,20 / 40);
                &:last-child{
                  border-bottom: none;
                }
                a{
                  font-size:16px;
                  transition: .3s;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                &:hover{
                  background:#fff;
                  >a{
                    color:$color-main;
                  }
                }
              }
            }
          }
        }
      }
    }
    .tel{
      .info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{
          white-space:nowrap;
          &:first-child{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @include res(margin-right,30px, 10 / 30);
            img{
              @include res(width,20px, 10 / 20);
            }
            span{
              color: #fff;
              @include res(margin-left,10px, 5 / 10);
              @include res(font-size,16px, 14 / 16);
            }
          }
          a{
            color: #fff;
            @include res(font-size,16px, 14 / 16);
          }
        }
      }
    }
    .mob-menu-icon{
      @include res(display,none,(sm:block));
      .hamburger{
        @include res(display,none,(sm:block));
        div{
          background: #fff;
        }
        div:before{
          background: #fff;
        }
        div:after{
          background: #fff;
        }
      }
    }
		.mobLogo{
			@include res(width,35%,(xs:40%));
			height: auto;
			@include res(display,none,(sm:block));
      @media (max-width: 415px){
        width: 67%;
      }
			img{
        @include res(width,100%);
				height: 100%;
				display: block;
				margin: 0 auto;
        object-fit: cover;
			}
		}
    .mob-lang{
      @include res(display,none,(sm:block));
      ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{
          a{
            color: #fff;
            @include res(font-size,16px, 14 / 16);
          }
        }
      }
    }
  }
  .mob-nav-mask{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1011;
    display: none;
  }
  .mob-nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1012;
    background:$color-main;
    color: #333;
    @include res(padding-top, 36px, 10 / 36);
    @include res(padding-right, 36px, 20 / 36);
    @include res(padding-bottom, 36px, 20 / 36);
    @include res(padding-left, 36px, 20 / 36);
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(-100%);
    transition: all $anime-duration;
    @include res(width, 30%, (xs:80%));
    &.show {
      transform: translateX(0);
    }
    .logoimg{
      width: 80%;
      margin: 0 auto;
      img{
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }
    .close-mob-nav {
      padding-bottom: 20px;
      text-align: right;
      .iconfont{
        @include res(font-size,26px, 16 / 26);
        font-weight: normal;
        cursor: pointer;
      }
    }
    .menu {
      max-height: 380px;
      overflow-y: auto;
      margin-top: 10px;
      ul {
        li {
          &.active {
            border-bottom: 1px solid rgba(255, 255, 255, .3);
            .menu-title {
              &::after {
                transform: translateY(-50%) rotate(0deg);
              }
            }
          }
          .menu-title {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #fff;
            @include res(font-size,18px, 14 / 18);
            a{
              display: block;
              padding: 15px 0 15px 10px;
              color: #fff;
            }
            .icon-iconguanbi{
              height: 100%;
              position: absolute;
              top: 0;
              right: 0;
              transition: all $anime-duration;
              font-size: 14px;
              color: rgba(255, 255, 255, .8);
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              @include res(width,50px, 30 / 50);
              color: #fff;
            }
          }
          .subnav {
            display: none;
            .subnav-item{
              padding-left: 30px;
              &:last-child{
                .menu-title2{
                  border-bottom: none;
                }
              }
              &.active{
                .menu-title2{
                  .iconfont{
                    &::before{
                      transform: rotate(180deg);
                    }
                  }
                }
              }
              .menu-title2{
                display: block;
                border-bottom: 1px solid #fff;
                position: relative;
                @include res(font-size,16px, 14 / 16);
                a{
                  display: block;
                  padding: 10px 0;
                  color: #fff;
                }
                .iconfont {
                  height: 100%;
                  position: absolute;
                  top: 0;
                  right: 0;
                  transition: all $anime-duration;
                  font-size: 12px;
                  color: rgba(255, 255, 255, .8);
                  cursor: pointer;
                  display: none;
                  align-items: center;
                  justify-content: center;
                  @include res(width,50px, 30 / 50);
                  &::before{
                    transform: rotate(90deg);
                    transition: all .3s;
                  }
                }
              }
            }
          }
        }
      }
    }
    .list{
      width: 100%;
      height: auto;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 99;
      background: #474342;
      padding: 40px 20px 20px;
      ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{
          a{
            display: flex;
            flex-flow: column;
            flex-wrap: wrap;
            align-items: center;
            .iconfont{
              font-size: 26px;
              color: #fff;
            }
            p{
              font-size: 14px;
              color: #fff;
              margin-top: 10px;
            }
          }
        }
      }
      .img{
        width: 75%;
        margin:20px auto 0;
        img{
          width: 100%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
	.model{
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.8);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		opacity: 0;
		visibility: hidden;
		transition: .3s;
		&.active{
			opacity: 1;
			visibility: visible;
		}
		img{
			width: 200px;
			height: 200px;
			display: block;
			margin: 0 auto;
		}
		.iconfont{
			font-size: 24px;
			color: #fff;
			margin-top: 40px;
			border: 1px solid #fff;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

